import React, {CSSProperties, FC} from "react";
import {convertStyles} from "../../../services/utils"

interface IProps {
    item: {
        buttonValue: string;
        settings: {};
        // linkValueButton: {
        //     id: string;
        //     value: string;
        //     checked: boolean
        // }
        check: boolean,
        linkValueButton: string,
    }
}


const Button: FC<IProps> = ({item}) => {
    let tagEntry = `<p>${item.buttonValue}</p>`;
    const setStyles = item.settings ? convertStyles(item.settings) : {parentStyles: {"text-align": ''}};
    //@ts-ignore
    const newStyles = {...setStyles.styles}
    // newStyles['border-bottom-left-radius'] = "5px";
    // newStyles['border-bottom-right-radius'] = "5px";
    // newStyles['border-top-left-radius'] = "5px";
    // newStyles['border-top-right-radius'] = "5px";
    // newStyles['padding'] = "12px 10px";
    newStyles['border'] = "none";
    newStyles['font-size'] = "16px";
    newStyles['cursor'] = "pointer";
    const { color: linkColor, "font-size": fontSizeLink, fontFamily: fontFamilyLink } = newStyles || {}
    const linkStyles = {
        color: linkColor,
        fontSize: fontSizeLink,
        fontFamily: fontFamilyLink
    }
    return (
        <div
            //@ts-ignore
            data-aos={setStyles?.animationAos['data-aos']}
            style={{...setStyles.parentStyles} as CSSProperties | undefined}
        >
            {/*<a*/}
            {/*    //FIXME the checkbox new tab logic is absence*/}
            {/*    // href={item.linkValueButton?.value ? item.linkValueButton.value : "/"}*/}
            {/*    href={item.linkValueButton ? item.linkValueButton : "/"}*/}
            {/*    target={item.check ? "_blank" : ""}*/}
            {/*    rel={"noreferrer"}*/}
            {/*>*/}
            <button style={newStyles}>
                <a
                    style={linkStyles}
                    href={item.linkValueButton ? item.linkValueButton : "/"}
                    rel={"noreferrer"}
                    dangerouslySetInnerHTML={{ __html: tagEntry }}
                    target={item.check ? "_blank" : ""}
                />
            </button>
            {/*</a>*/}
        </div>
    );
}

export default Button;
